<template>
  <div>
    <v-row class="mt-0 pt-0 px-5" justify="center">
      <v-col cols="12" class="py-0">
        <v-layout justify-center>
          <h2 class="color-text-main pt-4">USUARIOS</h2>
        </v-layout>
      </v-col>
      <v-col cols="11">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar usuario"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mt-4"
              fab
              small
              dark
              color="#E95233"
              @click="dialog.add = true"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Agregar usuario nuevo</span>
        </v-tooltip>
        <!-- <v-btn @click="dialog.add = true" fab icon>
          <v-icon> mdi-plus </v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :loading="loading.table"
          :search="search"
          loading-text="Cargando..."
        >
          <!-- Sin resultados de busqueda -->
          <template v-slot:no-results>
            <v-alert icon="mdi-information-outline" prominent text type="info">
              <b>No se encontraron resultados con: {{ search }}</b>
            </v-alert>
          </template>
          <!-- Sin Data -->
          <template v-slot:no-data>
            <v-alert icon="mdi-information-outline" prominent text type="info">
              <b>No se encontró información.</b>
            </v-alert>
          </template>
          <!-- Loading -->
          <template v-slot:progress>
            <v-progress-linear
              color="#214484"
              :height="5"
              indeterminate
              stream
            ></v-progress-linear>
          </template>
          <!-- Acciones -->
          <template v-slot:item.actions="{ item }">
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mt-0"
                  fab
                  x-small
                  dark
                  color="#E95233"
                  @click="openResetPass(item)"
                >
                  <v-icon small> mdi-email </v-icon>
                </v-btn>
              </template>
              <span>Reenviar correo electrónico</span>
            </v-tooltip> -->

             <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mt-0 ml-3"
              fab
              x-small
              dark
              color="#034f79"
              @click="openEdit(item)"
            >
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editar usuario</span>
        </v-tooltip> 

            <!-- <v-btn @click="openResetPass(item)" fab icon>
              <v-icon> mdi-email </v-icon>
            </v-btn> -->
            <!-- <v-btn @click="openEdit(item)" fab icon>
              <v-icon> mdi-pencil </v-icon>
            </v-btn> -->
          </template>

          <template v-slot:item.status.name="{ item }">
            <v-chip
              label
              small
              dark
              :color="item.status.name == 'Activo' ? 'green' : 'red'"
            >
              {{ item.status.name }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog.add" max-width="500">
      <v-card>
        <v-card-title>
          <v-layout justify-center> Registrar usuario </v-layout>
        </v-card-title>
        <v-card-text>
          <p>
            Recuerde que la contraseña, será envianda al correo electrónico
            registrado.
          </p>
          <v-form ref="form">
            <v-text-field
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form.name"
              label="Nombre"
              placeholder="Ingrese aquí el usuario"
              color="#034f79"
              outlined
            ></v-text-field>
            <v-text-field
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form.document_number"
              label="Número documento"
              placeholder="Ingrese aquí el número de documento"
              color="#034f79"
              outlined
            ></v-text-field>
            <v-text-field
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form.email"
              label="correo electrónico"
              placeholder="Ingrese aquí el correo electrónico"
              color="#034f79"
              outlined
            ></v-text-field>
            <v-autocomplete
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form.cost_center_id"
              item-value="id"
              item-text="name"
              :items="listCenterCost"
              outlined
              label="Centro de cotos *"
              color="#034f79"
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.campaign_id"
              item-value="id"
              item-text="name"
              :items="listCampaign"
              outlined
              label="Campaña "
              color="#034f79"
            ></v-autocomplete>
            <v-autocomplete
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form.role_id"
              item-value="id"
              item-text="name"
              :items="listRoleType"
              outlined
              label="Rol de usuario en la comisión *"
              color="#034f79"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                rounded
                @click="dialog.add = false"
                class="mb-5"
                color="#c1c1c1"
                dark
                block
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                rounded
                @click="save()"
                :loading="loading.btn"
                class="mb-5"
                color="#034f79"
                dark
                block
              >
                registrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.emailPassword" max-width="400">
      <v-card>
        <v-card-title class="pb-0">
          <v-layout justify-center>
            <p class="color-text-main mb-0">
              <v-icon x-large color="#E95233"> mdi-alert </v-icon>
            </p>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="text-info mb-2">
            ¿Estás seguro de desear <strong> resetear </strong> la contraseña?
          </p>
          <p class="mb-2">
            Una vez reseteé la contraseña el usuario no podrá ingresar con la
            que tiene actualmente.
          </p>
          <p class="mb-2">
            Se enviará una contraseña nueva a este correo electrónico
            <strong> {{ form.email }} </strong>.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                class="mb-5"
                @click="dialog.emailPassword = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="resetPassword()"
                color="#034f79"
                dark
                rounded
                block
              >
                resetear
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.edit" max-width="500">
      <v-card>
        <v-card-title>
          <v-layout justify-center> Editar usuario </v-layout>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="formEdit.name"
              label="Nombre"
              placeholder="Ingrese aquí el nombre del usuario"
              color="#034f79"
              outlined
            ></v-text-field>
            <v-text-field
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="formEdit.document_number"
              label="Número documento"
              placeholder="Ingrese aquí el número de documento"
              color="#034f79"
              outlined
            ></v-text-field>
            <v-text-field
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="formEdit.email"
              label="correo electrónico"
              placeholder="Ingrese aquí el correo electrónico"
              color="#034f79"
              outlined
            ></v-text-field>
            <v-autocomplete
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="formEdit.cost_center_id"
              item-value="id"
              item-text="name"
              :items="listCenterCost"
              outlined
              label="Centro de cotos *"
              color="#034f79"
            ></v-autocomplete>
            <v-autocomplete
              v-model="formEdit.campaign_id"
              item-value="id"
              item-text="name"
              :items="listCampaign"
              outlined
              label="Campaña *"
              color="#034f79"
            ></v-autocomplete>
            <v-autocomplete
              v-model="formEdit.status_id"
              item-value="id"
              item-text="name"
              :items="listStatus"
              outlined
              label="Estado *"
              color="#034f79"
            ></v-autocomplete>
            <v-autocomplete
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="formEdit.role_id"
              item-value="id"
              item-text="name"
              :items="listRoleType"
              outlined
              disabled
              label="Rol de usuario en la comisión *"
              color="#034f79"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                @click="dialog.edit = false"
                class="mb-5"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                @click="edit()"
                :loading="loading.btn"
                class="mb-5"
                color="#034f79"
                dark
                rounded
                block
              >
                Editar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog>
      <v-card> </v-card>
    </v-dialog> -->

    <v-overlay :value="loading.all">
      <v-progress-circular
        color="#e95233"
        width="8"
        indeterminate
        size="100"
      ></v-progress-circular
      ><br />
      <v-layout justify-center>
        <label class="mt-4" style="font-size: 20px">Cargando...</label>
      </v-layout>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import alert from "@/mixins/alert";
export default {
  mixins: [alert],
  data() {
    return {
      search: "",
      page: 1,
      itemsPerPage: 5,
      loading: {
        table: false,
        all: false,
        btn: false,
      },
      form: {
        name: "",
        document_number: "",
        email: "",
        cost_center_id: null,
        campaign_id: null,
        role_id: null,
      },
      formEdit: {
        user_id: null,
        name: "",
        document_number: "",
        email: "",
        cost_center_id: null,
        campaign_id: null,
        role_id: null,
        status_idx: null,
      },
      dialog: {
        add: false,
        emailPassword: false,
        edit: false,
      },
      itemsPerPageArray: [5, 10, 20, 50],
      headers: [
        {
          text: "Nombre",
          align: "start",
          filterable: false,
          value: "name",
        },
        { text: "Correo", value: "email" },
        { text: "Número de documento", value: "document_number" },
        { text: "Campaña", value: "campaign.name" },
        { text: "Centro de costos", value: "cost_center.name" },
        { text: "Estado", value: "status.name" },
        { text: "Rol en la comisión", value: "role.name" },
        { text: "Acciones", value: "actions" },
      ],
      items: [],
      listRoleType: [],
      listCenterCost: [],
      listCampaign: [],
      listStatus: [
        {
          id: 1,
          name: "Activo",
        },
        {
          id: 12,
          name: "Inactivo",
        },
      ],
      userId: null,
    };
  },
  methods: {
    save() {
      this.loading.btn = true;
      Api.Workforce()
        .createUsers(this.form)
        .then((res) => {
          this.alert(res.data.message, "Muy bien...", "success");
          this.dialog.add = false;
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btn = false;
        });
    },
    edit() {
      this.loading.btn = true;
      Api.Workforce()
        .updateUsers(this.formEdit)
        .then((res) => {
          this.getUsers();
          this.alert(res.data.message, "Muy bien...", "success");
          this.dialog.edit = false;
        })
        .catch((error) => {
          console.log(error);
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btn = false;
        });
    },
    resetPassword() {
      this.loading.btn = true;
      Api.Workforce()
        .resetPassword(this.userId)
        .then((res) => {
          this.alert(res.data.message, "Muy bien...", "success");
          this.dialog.emailPassword = false;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btn = false;
        });
    },
    openEdit(data) {
      this.dialog.edit = true;
      this.formEdit = {
        user_id: data.id,
        name: data.name,
        document_number: data.document_number,
        email: data.email,
        cost_center_id: data.cost_center ? data.cost_center.id : null,
        campaign_id: data.campaign ? data.campaign.id : null,
        role_id: data.role ? data.role.id : null,
        status_id: data.status_id,
      };
    },
    openResetPass(data) {
      this.dialog.emailPassword = true;
      this.userId = data.id;
    },
    getRoleType() {
      this.loading.all = true;
      Api.Workforce()
        .getRoles()
        .then((res) => {
          this.listRoleType = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getCenterCost() {
      this.loading.all = true;
      Api.Auth()
        .getCostCenter()
        .then((res) => {
          this.listCenterCost = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getCampaign() {
      this.loading.all = true;
      Api.Auth()
        .getCampaign()
        .then((res) => {
          this.listCampaign = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getUsers() {
      this.loading.all = true;
      Api.Workforce()
        .getUsers()
        .then((res) => {
          this.items = res.data.data;
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].role = {
              id: this.items[i].role_users[0].role
                ? this.items[i].role_users[0].role.id
                : null,
              name: this.items[i].role_users[0].role
                ? this.items[i].role_users[0].role.name
                : null,
            };
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
  },
  mounted() {
    this.getCenterCost();
    this.getCampaign();
    this.getUsers();
    this.getRoleType();
  },
};
</script>
